import React from 'react';
import {PageTitle} from "../../../utils";
import {
    Button, Divider,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import {MonthDropdown} from "../../../utils/dropdowns";
import {useParams} from "react-router-dom";
import moment from "moment-timezone";
import {
  getCareplansStatus,
  getResidentCareplanLog,
  getResidentCareplanLogEvents
} from "../../../../services/careplan.service";
import {getUniqueEventTemplates} from "../../../utils/table";
import {getStripedStyle} from "../../../utils/table.utils";
import TableLoadingIndicator from "../../../../utils/indicators/table.loading";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {AssignmentLateRounded, CheckCircle} from "@material-ui/icons";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {PrintTaskLogDiaglog} from "./print";
import {getResidentById} from "../../../../services/resident.service";
import useHasRole from "../../../../hooks/user.role.hook";
import CloseIcon from "@material-ui/icons/Close";
import {getAbbreviationFromName} from "../utils";
import {UserContext} from "../../../../context/usercontext";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        margin: theme.spacing(.5, 0)
    },
    tableDayColumn: {
        padding: 0,
        minWidth: "50px",
        maxWidth: "50px",
        textAlign: "center",
        borderLeft: "1px solid gray",
        borderRight: "1px solid gray",
    },
    legendContainer: {
        padding: theme.spacing(1)
    },
    legendItem: {
        display: 'flex',
        padding: theme.spacing(0, .1)
    },
    legendValue: {
        margin: theme.spacing(0, .2)
    },
    late: {
        color: theme.palette.warning.main
    },
    eTaskHeader: {
        minWidth: 225
    },
    hrHeader: {
        minWidth: 125
    },
    eventTemplateContainer: {

    },
    prnAlertContainer: {
        padding: theme.spacing(1),
        width: '100%',
        backgroundColor: theme.palette.grey[200]
    },
}))


export const ResidentTaskLog = () => {
  const {selectedTenant} = React.useContext(UserContext);
    const [loading, setLoading] = React.useState(false);
    const [viewPrintModal, setViewPrintModal] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [month, setMonth] = React.useState();
    const [year, setYear] = React.useState();
    const [days, setDays] = React.useState();
    const [etasks, setEtask] = React.useState();
    const [resident, setResident] = React.useState();
    const [etaskEvents, setEtaskEvents] = React.useState([]);
    const {residentId} = useParams();
    const classes = useStyles();
    const {checkPermission} = useHasRole();
    const [customStatus, setCustomStatus] = React.useState([]);
    const isReportPrinter = checkPermission("REPORT_PRINTER");

    React.useEffect(() => {
        const getResidentData = async () => {
            const resident = await getResidentById(residentId);
            setResident(resident);
        }
        if (residentId) {
            getResidentData();
        }
    }, [residentId]);

    const onDateSelection = async selection => {
        setMonth(selection?.month);
        setYear(selection?.year);
        const date = moment().set({year: selection.year, month: selection.month - 1});
        const daysInMonth = date.daysInMonth();
        setDays(daysInMonth)
        await getData(selection?.year, selection?.month - 1);
    }

    const getData = async (year, month) => {
        setLoading(true);
        setError(false);
        month = +month + 1;
        const response = await Promise.all([
            getResidentCareplanLog(residentId, year, month),
            getResidentCareplanLogEvents(residentId, year, month),
            getCareplansStatus(selectedTenant?._id)
        ])
        if (!response[0]?.error) {
            setEtask(response[0]);
        } else {
            setError(false);
        }
        if (!response[1]?.error) {
            setEtaskEvents(response[1]);
        } else {
            setError(false);
        }
        setCustomStatus(response[2])
        setLoading(false);
    }

    const getEventTemplateDisplayForTemplate = (frequency, template) => {
        switch (frequency) {
            case "QH":
                return "Every Hour";
            case "Q2H":
                return "Every 2 Hours";
            case "Q3H":
                return "Every 3 Hours";
            case "Q4H":
                return "Every 4 Hours";
            case "Q6H":
                return "Every 6 Hours";
            case "Q8H":
                return "Every 8 Hours";
            case "Q12H":
                return "Every 12 Hours";
            default:
                return `${template?.hour}:${template?.minute}`;
        }
    }

    const getPrnValue = (etask, day) => {
        const events = etaskEvents?.filter(event =>
            event?.isPrn &&
            etask?._id === event?.intervention &&
            moment.tz(event.scheduledTime, etask?.resident?.location?.timeZone ?? "US/Central").date() === +day
        );
        if (events?.length === 1) {
            const event = events[0];
            if (event?.status === "Given" && event?.administeredUser) {
                return (
                    <div style={{textAlign: "center"}}>
                        <CheckCircle color={"secondary"} className={classes.legendItem}/>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "Refused") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>R</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "Scheduled") {
                return <ScheduleIcon className={classes.legendItem}/>
            } else if (event?.status === "Late") {
                return <AssignmentLateRounded className={[classes.legendItem, classes.late]}/>
            } else if (event?.status === "Missed") {
                return <FiberManualRecordIcon color={"error"} className={classes.legendItem}/>
            } else if (event?.status === "NA") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>NA</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "Hold") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>H</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "SA") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>SA</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "Independent") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>I</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "Verbal prompt") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>VP</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "Physical Assist") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>PA</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else if (event?.status === "Minimal Assist") {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>MA</div>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            } else {
                return (
                    <div style={{textAlign: "center"}}>
                        <div>{event?.administeredUser?.initials}</div>
                    </div>
                )
            }
        }
        if (events?.length > 1) {
            console.log('multiple events', events);
            return (
                <div style={{textAlign: "center"}}>
                    <div>
                        {events[0]?.status[0]}
                    </div>
                    <div>{events[0]?.administeredUser?.initials}</div>
                </div>
            )
        } else {
            return "X";
        }
    }


    const getStatusLabel = status => {
      const abbreviation = getAbbreviationFromName(status, customStatus)
      if (abbreviation) {
        return abbreviation;
      }



        switch (status) {
            case 'Scheduled':
                return <ScheduleIcon />;
            case 'Given':
                return <CheckCircle color={"secondary"} />;
            case 'Missed':
                return <FiberManualRecordIcon color={"error"} />;
            case 'Refused':
                return 'R';
            case 'Hold':
                return 'H';
            case 'Refused & Destructed':
            case 'RD':
                return 'RD';
            case 'Self Administered':
            case 'SA':
                return 'SA';
            case "Independent":
                return "I";
            case "Verbal prompt":
                return "VP";
            case "Physical Assist":
                return "PA";
            case "Minimal Assist":
                return "MA";
            case 'Not Administered':
            case 'NA':
                return 'NA';
            case 'Late':
                return <AssignmentLateRounded className={classes.late}  />
            default:
                return <CloseIcon />;
        }
    }

    const getValue = (etask, eventTemplate, day) => {
        const everyXHourFrequencys = [ "QH", "Q2H", "Q3H", "Q4H", "Q6H", "Q8H","Q12H" ];
        const frequencyName = etask?.frequency?.name;
        let events = [];
        let isEveryXHoursFrequency = everyXHourFrequencys?.indexOf(frequencyName) > -1
        if (isEveryXHoursFrequency) {
            events = etaskEvents?.filter(event =>
                etask?._id === event?.intervention &&
                moment.tz(event.scheduledTime, etask?.resident?.location?.timeZone ?? "US/Central").date() === +day
            );
        } else {
            events = etaskEvents?.filter(event =>
                etask?._id === event?.intervention &&
                moment.tz(event.scheduledTime, etask?.resident?.location?.timeZone ?? "US/Central").date() === +day &&
                moment.tz(event.scheduledTime, etask?.resident?.location?.timeZone ?? "US/Central").hour() === +eventTemplate.hour &&
                moment.tz(event.scheduledTime, etask?.resident?.location?.timeZone ?? "US/Central").minute() === +eventTemplate.minute
            );
        }
        if (events?.length > 0) {
            return events?.map(event =>
                <>
                    <div style={{
                        position: 'relative',
                        height: isEveryXHoursFrequency ? '80px': '50px', width: '100%'}}>
                        {getStatusLabel(event?.status)}
                        {isEveryXHoursFrequency &&
                            <div style={{width: '100%'}}>
                                {moment(event?.scheduledTime).format("H:mm")}
                            </div>
                        }
                        {event?.administeredUser &&
                            <div style={{position: 'absolute', right: '0', bottom: '0', marginTop: isEveryXHoursFrequency ? "-10px": 0}}>
                                <Typography variant={"subtitle2"}
                                            style={{margin: 0, padding: 0, width: '50px', textAlign: "center"}}
                                >
                                    {event?.administeredUser?.initials}
                                </Typography>
                            </div>
                        }
                    </div>
                    {isEveryXHoursFrequency ? <Divider />: null}
                </>
            )
        } else {
            return (
                <div style={{
                    position: 'relative',
                    height: '50px', width: '100%'
                }}>
                    <CloseIcon />
                </div>
            )
        }
    }

    return (
        <Paper className={classes.root} elevation={24}>
            <Grid container
                  justifyContent={"space-between"}>
                <Grid item>
                    <PageTitle title={"Resident Task Log"}/>
                </Grid>
                {isReportPrinter &&
                    <Grid item>
                        <Button disabled={loading && resident}
                                onClick={() => setViewPrintModal(true)}
                                startIcon={<PictureAsPdfIcon/>}>
                            Print
                        </Button>
                        <PrintTaskLogDiaglog open={viewPrintModal}
                                             days={days}
                                             year={year}
                                             month={month}
                                             resident={resident}
                                             etasks={etasks}
                                             etaskEvents={etaskEvents}
                                             customStatus={customStatus}
                                             onClose={() => setViewPrintModal(false)}/>
                    </Grid>
                }
            </Grid>
            {residentId && <MonthDropdown onSelection={onDateSelection}/>}
            <Grid container
                  className={classes.legendContainer}
            >
                <div className={classes.legendItem}>
                    <ScheduleIcon className={classes.legendItem}/>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Scheduled</Typography>
                </div>
                <div className={classes.legendItem}>
                    <CheckCircle color={"secondary"} className={classes.legendItem}/>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Given</Typography>
                </div>
                <div className={classes.legendItem}>
                    <FiberManualRecordIcon color={"error"} className={classes.legendItem}/>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Missed</Typography>
                </div>
                <div className={classes.legendItem}>
                    <AssignmentLateRounded className={[classes.legendItem, classes.late]}/>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Late</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>R</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Refused</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>H</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Hold</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>RD</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Refused & Destructed</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>SA</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Self Administered</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>NA</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Not Administed</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>I</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Independent</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>VP</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Verbal prompt</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>PA</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = Physical Assist</Typography>
                </div>
                <div className={classes.legendItem}>
                    <Typography variant={"subtitle2"} className={classes.legendItem}>Minimal Assist</Typography>
                    <Typography variant={"subtitle2"} className={classes.legendValue}> = MA</Typography>
                </div>
            </Grid>
            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.eTaskHeader}>eTask</TableCell>
                            <TableCell className={classes.hrHeader}>HR</TableCell>
                            {[...Array(days)].map((e, i) => (
                                <TableCell className={classes.tableDayColumn}>{i + 1}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {loading && <TableLoadingIndicator bodyOnly={true} rows={5} cols={2 + days}/>}
                    <TableBody>
                        {!loading && !error && etasks?.filter(etask => !etask?.isPrn)?.map((etask, i) =>
                            <TableRow style={getStripedStyle(i)}>
                                <TableCell className={classes.eTaskHeader}>
                                    {etask.name}
                                </TableCell>
                                <TableCell className={classes.hrHeader}>
                                    {getUniqueEventTemplates(etask?.eventTemplates)?.map(eventTemplate =>
                                        <div className={classes.eventTemplateContainer}>
                                            {getEventTemplateDisplayForTemplate(etask?.frequency?.name, eventTemplate)}
                                        </div>
                                    )}
                                </TableCell>
                                {[...Array(days)].map((e, i) => (
                                    <TableCell className={classes.tableDayColumn}>
                                        {getUniqueEventTemplates(etask?.eventTemplates)?.map(eventTemplate =>
                                            <div className={classes.eventTemplateContainer}>
                                                {getValue(etask, eventTemplate, i + 1)}
                                            </div>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                        {!loading && !error && etasks?.filter(etask => etask?.isPrn)?.length > 0 &&
                            <TableRow>
                                <TableCell className={classes.prnAlertContainer} colSpan={days}>
                                    <Typography variant={"subtitle1"}>PRNs</Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {!loading && !error && etasks?.filter(etask => etask?.isPrn)?.map((etask, i) =>
                            <TableRow style={getStripedStyle(i)}>
                                <TableCell className={classes.eTaskHeader}>
                                    {etask.name}
                                </TableCell>
                                <TableCell className={classes.hrHeader}>
                                    N/A
                                </TableCell>
                                {[...Array(days)].map((e, i) => (
                                    <TableCell className={classes.tableDayColumn}>
                                        <div className={classes.eventTemplateContainer}>
                                            {getPrnValue(etask, i + 1)}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
