import React from 'react';
import {
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Hidden,
  Divider,
  Tooltip
} from '@material-ui/core';
import moment from 'moment';
import {
  green,
  yellow,
  red,
  grey,
  orange,
  blue,
} from '@material-ui/core/colors';
import { Link as RouterLink } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import {ListLoading} from "../../utils/indicators/list.loading";
import {displayHoldInfo, isMedicationOrderOnHold} from "../resident/medicationorders/emar/utils";

const contrast = 400;
const useStyles = makeStyles((theme) => ({
  emar: {},
  emarSelected: {
    background: theme.palette.grey[200],
    borderColor: theme.palette.grey[500],
  },
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: '.5rem',
  },
  backBtn: {
    marginRight: '.5rem',
  },
  gridContainer: {
    padding: theme.spacing(1),
  },
  listRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  calendarBtn: {
    margin: theme.spacing(0, 1),
  },
  listItem: {
    borderBottom: '.25px solid',
    borderColor: theme.palette.grey[50],

  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    background: theme.palette.primary,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  readyToPassIcon: {
    color: grey[700]
  },
  given: {
    background: green[contrast],
  },
  givenIcon: {
    color: green[contrast]
  },
  late: {
    background: orange[contrast],
  },
  lateIcon: {
    color: orange[contrast]
  },
  missed: {
    background: red[contrast],
  },
  missedIcon: {
    color: red[contrast]
  },
  hold: {
    background: yellow[contrast],
  },
  holdIcon: {
    color: yellow[contrast]
  },
  sa: {
    background: blue[contrast],
  },
  saIcon: {
    color: blue[contrast]
  },
  na: {
    background: grey[contrast],
  },
  naIcon: {
    color: grey[contrast]
  },
  refused: {
    background: orange[contrast],
  },
  refusedIcon: {
    color: orange[contrast]
  },
  destruction: {
    background: yellow[contrast],
  },
  destructionIcon: {
    color: yellow[contrast]
  },
  status: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  secondaryActions: {
    position: 'relative',
    right: '0',
    transform: 'translate(0)',
  },
  chartVitalsIndicator: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    right: 3,
    bottom: 3,
  },
  isHold: {
    backgroundColor: yellow[100]
  }
}));

const MobileEmar = ({ emar,
                      onSelected,
                      isSelected = false,
                      canUpdate = true,
                      quickUpdate,
                      date,
                      autoFocus,
                      frequencies
                    }) => {
  const classes = useStyles();
  const myRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const { roles } = React.useContext(UserContext);
  // const [frequencies, setFrequencies] = React.useState([]);


  // const fetchFreqs = async () => {
  //   const frequencyResponse = await getFrequencies();
  //   if (!frequencyResponse?.error) {
  //     setFrequencies(frequencyResponse);
  //   }
  // }

  // React.useEffect(() => {
  //   fetchFreqs();
  // }, [])

  const onQuickUpdate = async (event, emar, status) => {
    setLoading(true);
    event.stopPropagation();
    await quickUpdate(emar, status);
    setLoading(false);
  }

  const canEdit = (event) => {
    if (!canUpdate) return false;
    const current = moment();
    const start = moment(event.startWindow);
    const end = moment(event.endWindow);
    let updateMissedMedicationPermissions = false;
    if (roles) {
      updateMissedMedicationPermissions =
        roles.filter(
          (userRole) =>
            userRole.role.permissions.indexOf('UPDATE_MISSED_MEDICATION') > -1
        ).length > 0;
    }
    if (updateMissedMedicationPermissions && current >= start) {
      return true;
    } else if (current >= start && current < end) {
      return true;
    } else {
      return false;
    }
  };

  const getItemClass = (event) => {
    if (event && event.status && event.status.name) {
      switch (event.status.name) {
        case 'Scheduled':
          if (canEdit(event)) {
            return classes.readyToPass;
          } else {
            return classes.scheduled;
          }
        case 'Given':
          return classes.given;
        case 'Late':
          return classes.late;
        case 'Missed':
          return classes.missed;
        case 'Hold':
          return classes.hold;
        case 'SA':
          return classes.sa;
        case 'NA':
          return classes.na;
        case 'Refused':
          return classes.refused;
        case 'Destruction':
          return classes.destruction;
        default:
          return classes.scheduled;
      }
    }
  };



  const getFrequencyDetails = emar => {
    const dosageInstruction = emar?.medicationOrder?.dosageInstructions?.find(di => di._id === emar?.dosageInstruction ||
    di === emar?.dosageInstruction ||
    di?._id === emar?.dosageInstruction?._id);
    if (dosageInstruction) {
      const found = frequencies?.find(f => f._id === dosageInstruction.frequency || f._id === dosageInstruction?.frequency?._id);
      if (found) {
        return `${found.name} - ${found.description}`;
      }
    }
    return null;
  }

  const inHoldRange = order => {
    return moment(date).isBetween(order?.hold?.startDate, order?.hold?.endDate, 'day', '[]');
  }

  React.useEffect(() => {
    if (emar?.scrollTo && myRef) {
      myRef.current.scrollIntoView(false, {behavior: 'auto', block: 'end' });
    }
  },[emar, myRef]);


  if (loading) {
    return (
      <ListLoading rows={1}
                   hideProgress={true}
                   height={15}
      />
    )
  }

  return (
    <ListItem
      key={emar?._id}
      divider
      button
      onClick={() => onSelected(emar)}
      ref={myRef}
      className={isMedicationOrderOnHold(emar?.medicationOrder, date) ? classes.isHold: null}
    >
      <ListItemAvatar>
        <>
          {!isMedicationOrderOnHold(emar?.medicationOrder, date) &&
          <>
            <Avatar className={getItemClass(emar)}>
              <i className="fas fa-prescription-bottle"></i>
            </Avatar>
            {!emar?.chartVitals && <div style={{ marginTop: '1rem'}}>
              {canEdit(emar) && emar?.status?.name === (emar?.medicationOrder?.defaultQuickStatus ?? 'Given') &&
                <Tooltip title={"Given"}>
                  <Avatar color={"secondary"}
                          className={[getItemClass(emar)]}
                          onClick={(evt) => onQuickUpdate(evt, emar, "Scheduled")}>
                    <i className={"fas fa-check"}></i>
                  </Avatar>
                </Tooltip>
              }
              {canEdit(emar) && emar?.status?.name !== (emar?.medicationOrder?.defaultQuickStatus ?? 'Given') &&
                <Tooltip title={"Not Given"}>
                  <>
                    <Avatar onClick={(evt) => onQuickUpdate(evt,emar, emar?.medicationOrder?.defaultQuickStatus ?? 'Given')}
                            className={getItemClass(emar)}
                    >
                      <i className={["fas fa-circle", getItemClass(emar)]}></i>
                    </Avatar>

                  </>
                </Tooltip>
              }
            </div>}
          </>
          }
          {isMedicationOrderOnHold(emar?.medicationOrder, date) &&
            <div>
              {displayHoldInfo(emar?.medicationOrder, date, true)}
            </div>
          }
        </>
      </ListItemAvatar>
      <Hidden xsDown>
        <ListItemText
          primary={
            <Typography
              onClick={(e) => e.stopPropagation()}
              component={RouterLink}
              to={`/residents/${emar?.resident?._id}/emar`}
            >
              {emar?.resident ? (
                <Tooltip title={emar?._id}>
                  <span id="residentname">
                    {emar?.resident?.firstName} {emar?.resident?.lastName}
                  </span>
                </Tooltip>
              ) : null}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography component={"span"} variant="subtitle2">
                {emar.medication ? emar.medication.tradeName : null}
              </Typography>
              <br />
              <Typography component={"span"} variant="subtitle2">
                Qty: {emar.passingQty}
              </Typography>
              <br />
              <Typography
                component={"span"}
                style={{
                  fontWeight: 'bolder',
                  fontSize: '1.25rem',
                }}
              >
                {emar.scheduledTime &&
                  moment(emar.scheduledTime).format('h:mm a')}
              </Typography>
            </React.Fragment>
          }
        />
        <div style={{ width: '100%', padding: '.4rem' }}>
          <React.Fragment>
            <Typography variant="subtitle2">
              {getFrequencyDetails(emar)}
            </Typography>
            <Typography variant="subtitle2">
              Strength: {emar.medication ? emar.medication.strength : null}{' '}
              {emar.medication && emar.medication.unit
                ? emar.medication.unit.name
                : null}
            </Typography>
            <Typography variant="subtitle2">
              Direction: {emar.direction}
            </Typography>
            <Typography variant="subtitle2">
              Indication: {emar.indication}
            </Typography>
            <Typography variant="subtitle2">
              Pill: {emar.pillDescription}
            </Typography>
          </React.Fragment>
        </div>
      </Hidden>
      <Hidden smUp>
        <ListItemText
          primary={
            <Typography
              onClick={(e) => e.stopPropagation()}
              component={RouterLink}
              to={`/residents/${emar?.resident?._id}/emar`}
            >
              {emar.resident ? (
                <span>
                  {emar.resident.firstName} {emar.resident.lastName}
                </span>
              ) : null}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography variant="subtitle2">
                {emar.medication ? emar.medication.tradeName : null}
              </Typography>
              <Typography variant="subtitle2">
                Strength: {emar.medication ? emar.medication.strength : null}{' '}
                {emar.medication && emar.medication.unit
                  ? emar.medication.unit.name
                  : null}
              </Typography>
              <Typography variant="subtitle2">
                Qty: {emar.passingQty}
              </Typography>
              <Typography variant="subtitle2">
                {getFrequencyDetails(emar)}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bolder',
                  fontSize: '1.25rem',
                }}
              >
                {emar.scheduledTime &&
                  moment(emar.scheduledTime).format('h:mm a')}
              </Typography>
            </React.Fragment>
          }
        />
      </Hidden>
      <Divider />
      {emar?.chartVitals &&
        <Avatar className={classes.chartVitalsIndicator}>
          <i className="fas fa-heartbeat"></i>
        </Avatar>
      }
      <ListItemSecondaryAction>
        <span>
          <Typography style={{ textAlign: 'right' }} variant="subtitle2">
            {emar?.status?.name}
          </Typography>
          {(emar?.medicationOrder?.orderStatus?.name === "Hold" ||
            emar?.medicationOrder?.orderStatus === "634041d42320207c6126468f") && inHoldRange(emar?.medicationOrder) &&
            <Typography style={{ textAlign: 'right' }} variant={"subtitle1"}>
              {emar?.medicationOrder?.hold?.comment}
            </Typography>
          }
          {emar.initials && (
            <Typography style={{ textAlign: 'right' }} variant="subtitle2">
              {emar.initials} -{' '}
              {moment(emar.administeredTimestamp).format('h:mm a')}
            </Typography>
          )}
        </span>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default MobileEmar;
