import React, {useContext, useState, useEffect} from 'react';
import {PageTitle} from "../../../components/utils";
import {
  Box,
  Button,
  ButtonGroup, FormControlLabel, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  createCareplanStatus,
  deleteCareplanStatus,
  getCareplansStatus,
  resetCareplanStatus,
  updateCareplanStatus
} from "../../../services/careplan.service";
import {UserContext} from "../../../context/usercontext";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {RetryMessage} from "../../../components/notification/retry.message";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import useGlobalNotification from "../../../hooks/notification.hook";
import {CreateStatusModal} from "./Create";
import {getTenantFeatures, updateTenantFeatures} from "../../../services/tenant.service";

export const CareplansAdminPage = () => {
  const { selectedTenant } = useContext(UserContext);
  const [tenantFeatures, setTenantFeatures] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [viewAddStatusModal, setViewAddStatusModal] = useState(false);
  const {addNotification} = useGlobalNotification();

  useEffect(() => {
    getFeatures().then(() => {});
    // eslint-disable-next-line
  }, [selectedTenant?._id]);

  useEffect(() => {
    if (tenantFeatures?.careplans?.customStatus?.enabled) {
      getData().then(() => {})
    }
    // eslint-disable-next-line
  }, [tenantFeatures?.careplans?.customStatus?.enabled])

  const getFeatures = async () => {
    const response = await getTenantFeatures(selectedTenant?._id);
    if (!response?.error) {
      setTenantFeatures(response);
    }
  }

  const getData = async () => {
    setLoading(true);
    setError(false);
    const response = await getCareplansStatus(selectedTenant?._id);
    if (!response?.error) {
      setStatuses(response)
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const resetStatus = async () => {
    setLoading(true);
    setError(false);
    const response = await resetCareplanStatus(selectedTenant?._id);
    if (!response?.error) {
      setStatuses(response)
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const updateSort = async (status, direction) => {
    setLoading(true);
    setError(false);
    let obj;
    let objValue = status.sort;
    if (direction === 'up' && status.sort > 1) {
      if (status.sort > 1) {
        objValue = status.sort - 1
        obj = statuses?.map(s => {
          if (s.sort === objValue) {
            s.sort = s.sort + 1
          }
          if (s._id === status._id) {
            s.sort = objValue
          }
          return s;
        });
        const response = await updateCareplanStatus(selectedTenant?._id, status?._id, {...status, sortDirection: 'up'});
        if (!response?.error) {
          setStatuses(obj);
        } else {
          addNotification("Unable to update status", "error");
        }
      }
    } else if (direction === "down" && status.sort < (statuses?.length + 1)) {
      objValue = status.sort + 1
      if (objValue < (statuses?.length + 1)) {
        obj = statuses?.map(s => {
          if (s.sort === objValue) {
            s.sort = s.sort - 1
          }
          if (s._id === status._id) {
            s.sort = objValue
          }
          return s;
        });
        const response = await updateCareplanStatus(selectedTenant?._id, status?._id, {...status, sortDirection: 'down'});
        if (!response?.error) {
          setStatuses(obj);
        } else {
          addNotification("Unable to update status", "error");
        }
      }
    }
    setLoading(false);
  }

  const deleteStatus = async status => {
    setLoading(true);
    setError(false);
    const response = await deleteCareplanStatus(selectedTenant?._id, status?._id);
    if (!response?.error) {
      setStatuses(response)
    }
    setLoading(false);
  }

  const handleAddNewStatus = async status => {
    setLoading(true);
    setError(false);
    status.sort = statuses.length + 1
    const response = await createCareplanStatus(selectedTenant?._id, status);
    setViewAddStatusModal(false);
    setStatuses([...statuses, response])
    setLoading(false);
  }

  const onChange = async (e) => {
    const obj = {...tenantFeatures}
    if (obj?.careplans && obj?.careplans?.customStatus) {
      obj.careplans.customStatus.enabled = e.target.checked;
    } else {
      obj.careplans = {
        customStatus: {
          enabled: e.target.checked
        }
      }
    }
    setTenantFeatures(obj);
    const response = await updateTenantFeatures(selectedTenant?._id, obj);
    if (!response?.error) {
      setTenantFeatures(response);
      addNotification("Tenant features updated.", "success");
     } else {
      addNotification("Unable to update tenant feature", "error");
    }
  }

  return (
    <div>
      <CreateStatusModal open={viewAddStatusModal}
                         onCancel={() => setViewAddStatusModal(false)}
                         onSave={handleAddNewStatus}
      />
      <PageTitle title={"Careplan Administration"} />
      <div>
        <FormControlLabel
          style={{
            alignSelf: 'flex-start',
            margin: '1rem 0 0 0',
          }}
          name={"custom-statuses"}
          control={
            <Switch
              name={"custom-statuses"}
              onChange={onChange}
              color="primary"
              checked={tenantFeatures?.careplans?.customStatus?.enabled || ''}
            />
          }
          label={"Custom eTask Labels"}
          labelPlacement="start"
        />
      </div>
      {tenantFeatures?.careplans?.customStatus?.enabled &&
        <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Button startIcon={<AddIcon />}
                        onClick={() => setViewAddStatusModal(true)}
                        variant={"outlined"}>
                  Add
                </Button>
              </TableCell>
              <TableCell>

              </TableCell>
              <TableCell>
                <Box display="flex" justifyContent="flex-end">
                  <Button startIcon={<RefreshIcon />}
                          onClick={resetStatus}
                          variant={"outlined"}>
                    Reset
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={350} />
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Abbreviation
              </TableCell>
            </TableRow>
          </TableHead>
          {!error && loading &&
            <TableLoadingIndicator cols={3} rows={8} bodyOnly={true} />
          }
          <TableBody>
            {!error && !loading &&
              statuses?.sort((a, b) => a?.sort > b?.sort ? 1: -1)?.map(status =>
              <TableRow>
                <TableCell>
                  <Box display={"flex"}
                       justifyContent={status?.required === true ? "flex-end": "space-between"}>
                    {!status?.required && <Button startIcon={<DeleteIcon />} size={"small"} onClick={() => deleteStatus(status)}>Delete</Button>}
                    <ButtonGroup size={"small"}>
                      <Button startIcon={<ArrowUpward />}
                              onClick={() => updateSort(status, 'up')}>
                        Sort
                      </Button>
                      <Button startIcon={<ArrowDownward />}
                              onClick={() => updateSort(status, 'down')}>
                        Sort
                      </Button>
                    </ButtonGroup>
                  </Box>
                </TableCell>
                <TableCell>{status.name} ({status.sort})</TableCell>
                <TableCell>{status.abbreviation}</TableCell>
              </TableRow>
              )
            }
            {error && !loading &&
              <TableRow>
                <TableCell colSpan={3}>
                  <RetryMessage message={"Unable to load careplan statues, please try again."}
                                onRetry={getData}
                                severity={"error"} />
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      }
    </div>
  )
}
