import React from 'react';
import {
  Button,
  Divider, Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { ResidentListContext } from './resident.list.context';
import TocIcon from '@material-ui/icons/Toc';
import AppsIcon from '@material-ui/icons/Apps';
import ResidentsTable from './residents.table';
import ResidentListPagination from './resident.pagination';
import { getResidents } from './actions/resident.actions';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import history from '../../../utils/history';
import { convertToFilters } from '../../../utils/filter.utils';
import filterTemplate from './filterTemplate';
import {RetryMessage} from "../../notification/retry.message";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
  },
  btnContainer: {
    display: 'flex',
  },
  header: {
    height: theme.spacing(6),
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ResidentList = ({ minView = false, forceMaxHeight = false }) => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(ResidentListContext);

  const setDisplayTable = (val) => {
    dispatch({ type: 'setDisplayType', payload: val });
  };

  const clearFilters = async () => {
    dispatch({ type: 'setLoading' });
    const locationFilter = state.filter.tenantLocationId;
    const obj = JSON.parse(JSON.stringify(filterTemplate));
    obj.tenantLocationId = locationFilter;
    dispatch({type: 'CLEAR_FILTERS'});
    dispatch({ type: 'setLoading' });
    const filters = convertToFilters(obj);
    const data = await getResidents(
      state.page,
      state.size,
      filters,
      state.sort,
      state.sortDirection
    );
    if (!data?.error) {
      dispatch({ type: 'setResidents', payload: data });
    } else {
      dispatch({type: 'searchResidentsError'})
    }
  };

  const onResidentSelect = (resident) => {
    if (
      resident &&
      resident.residentStatus &&
      resident.residentStatus.name === 'Enrollment In Progress'
    ) {
      history.push(`/intake/${resident?._id}`);
    } else {
      history.push(`/residents/${resident._id}`);
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <div>
          <Button startIcon={<AddOutlinedIcon />}
                  component={Link}
                  to={`/intake/new`}
          >
            Add Resident
          </Button>
          <Button startIcon={<HighlightOffSharpIcon />} onClick={clearFilters}>
            Clear Filters
          </Button>
        </div>
        <Grid container alignItems={"center"} justifyContent={"flex-end"}>
          <IconButton
            title="Display Table"
            onClick={() => setDisplayTable(true)}
            style={{padding: '0 12px'}}
          >
            <TocIcon />
          </IconButton>
          <IconButton
            title="Display Grid"
            onClick={() => setDisplayTable(false)}
            style={{padding: '0 12px'}}
          >
            <AppsIcon />
          </IconButton>
        </Grid>
        {/*<ButtonGroup aria-label="button group">*/}
        {/*  <IconButton*/}
        {/*    title="Display Table"*/}
        {/*    onClick={() => setDisplayTable(true)}*/}
        {/*  >*/}
        {/*    <TocIcon />*/}
        {/*  </IconButton>*/}
        {/*  <IconButton*/}
        {/*    title="Display Grid"*/}
        {/*    onClick={() => setDisplayTable(false)}*/}
        {/*  >*/}
        {/*    <AppsIcon />*/}
        {/*  </IconButton>*/}
        {/*</ButtonGroup>*/}
      </div>
      <Divider />
      {!state?.error &&
      <>
        <ResidentListPagination />
        <ResidentsTable
          onSelected={onResidentSelect}
          minView={minView}
          forceMaxHeight={forceMaxHeight}
        />
      </>
      }
      {!state?.loading && state?.error &&
      <RetryMessage onRetry={clearFilters} />
      }
    </div>
  );
};

export default ResidentList;
