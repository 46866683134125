import React from 'react';
import {
  Avatar, Box,
  Button, Chip,
  Grid,
  Link,
  makeStyles,
  Paper,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow, TableSortLabel, TextField,
  Typography
} from "@material-ui/core";
import {Link as RouterLink, Switch} from 'react-router-dom'
import {HighlightOffTwoTone, WarningRounded} from "@material-ui/icons";
import {getUsers} from "../../services/user.service";
import {getStripedStyle} from "../utils/table.utils";
import AddIcon from "@material-ui/icons/Add";
import {UserContext} from "../../context/usercontext";
import {convertToFilters} from "../../utils/filter.utils";
import FilterTemplate from './filter.template';
import {CreateUserModal} from "./create"
import PrivateRoute from "../PrivateRoute";
import {User} from "./user/user";
import useHasRole from "../../hooks/user.role.hook";
import FaceIcon from "@material-ui/icons/Face";
import {PageTitle} from "../utils";
import TableLoadingIndicator from "../../utils/indicators/table.loading";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0,1)
  },
  avatar: {
    background: theme.palette.warning.main,
    marginRight: theme.spacing(2)
  }
}))




export const Users = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(25);
  const [total, setTotal] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const [sortOrderBy, setSortOrderBy] = React.useState('lastName');
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [filter, setFilter] = React.useState(JSON.parse(JSON.stringify(FilterTemplate)));
  const [showCreateUser, setShowCreateUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { selectedTenant } = React.useContext(UserContext);
  const { checkPermission } = useHasRole();
  const canReadUsers = checkPermission('USER_READ');
  const canCreateUsers = checkPermission('USER_WRITE');

  React.useEffect(() => {
    if (selectedTenant) {
      getData(selectedTenant?._id);
    } else {
      getData([]);
    }
  },[selectedTenant]);

  const getData = async (tenantId, pg = 0, sz = 25, filt = {}, srt = 'lastName', srtDirection = 'asc') => {
    setLoading(true);
    const obj = {...filt};
    const filters = convertToFilters(obj);
    const sort = `${srtDirection === 'asc' ? '+': '-'}${srt}`;
    const response = await getUsers(pg + 1, sz, sort, filters);
    setUsers(response?.content);
    setPage(response?.currentPage - 1);
    setSize(response?.perPage);
    setTotal(response?.totalItems);
    setLoading(false);
  }

  const handleChangePage = async (event, pg) => {
    setPage(pg);
    await getData(selectedTenant?._id, pg, size, filter, sortOrderBy, sortDirection);
  };

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await getData(selectedTenant?._id, page, event.target.value, filter, sortOrderBy, sortDirection);
  };


  const createSortHandler = async field => {
    setSortOrderBy(field);
    const direction = sortDirection === 'asc' ? 'desc' : 'asc'
    setSortDirection(direction);
    await getData(selectedTenant?._id, page, size, filter, field, direction);
  }

  const filterTextChange = async e => {
    const obj = {...filter};
    obj[e.target.name].value = e.target.value;
    setFilter(obj);
    if (e.target.value.length >= obj[e.target.name].minValue) {
      await getData(selectedTenant?._id, page, size, obj, sortOrderBy, sortDirection)
    } else if (e.target.value.length === 0) {
      await getData(selectedTenant?._id, page, size, obj, sortOrderBy, sortDirection)
    }
  }

  const clearFilters = async () => {
    const obj = JSON.parse(JSON.stringify(FilterTemplate));
    setFilter(obj);
    const pg = 0;
    setPage(pg);
    const sz = 25;
    setSize(25);
    const sort = 'lastName';
    setSortOrderBy(sort);
    const direction = 'asc';
    setSortDirection(direction);
    await getData(selectedTenant?._id, pg,  sz, obj, sort, direction);
  }

  const saveUser = async user => {
    const obj = [user,  ...users];
    setUsers(obj);
    setShowCreateUser(false)
  }

  if (canReadUsers) {
    return (
      <Paper elevation={24}
             className={classes.root}
      >
        <PageTitle title={"Users"} />
        <div>
          <Grid container justify={"space-between"} alignItems={"center"}>
            <div>
              {canCreateUsers && <Button startIcon={<AddIcon/>}
                      onClick={() => setShowCreateUser(true)}
              >Create User</Button>}
              <CreateUserModal open={showCreateUser}
                               tenant={selectedTenant}
                               save={saveUser}
                               onClose={() => setShowCreateUser(false)}
              />
              <Button startIcon={<HighlightOffTwoTone/>}
                      onClick={clearFilters}
              >Clear Filters</Button>
            </div>
            <TablePagination component={"div"}
                             rowsPerPageOptions={[1, 5, 10, 25, 50]}
                             page={page}
                             rowsPerPage={size}
                             count={total}
                             children={<Button>Clear</Button>}
                             backIconButtonProps={{
                               'aria-label': 'previous page',
                               disabled: page === 0 ? true : false,
                             }}
                             nextIconButtonProps={{
                               'aria-label': 'next page',
                               disabled: (page + 1) * size > total ? true : false,
                             }}
                             onChangeRowsPerPage={onChangeRowsPerPage}
                             onChangePage={handleChangePage}
            />

          </Grid>
          <TableContainer>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell/>
                  <TableCell>
                    <TableSortLabel
                      active={sortOrderBy === 'firstName'}
                      direction={sortDirection}
                      onClick={() => createSortHandler('firstName')}
                    >
                      First Name
                    </TableSortLabel>
                    <TextField
                      fullWidth
                      name="firstName"
                      margin="dense"
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      value={filter?.firstName?.value || ''}
                      onChange={filterTextChange}
                    ></TextField>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortOrderBy === 'lastName'}
                      direction={sortDirection}
                      onClick={() => createSortHandler('lastName')}
                    >
                      Last Name
                    </TableSortLabel>
                    <TextField
                      fullWidth
                      name="lastName"
                      margin="dense"
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      value={filter?.lastName?.value || ''}
                      onChange={filterTextChange}
                    ></TextField>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortOrderBy === 'email'}
                      direction={sortDirection}
                      onClick={() => createSortHandler('email')}
                    >
                      Email
                    </TableSortLabel>
                    <TextField
                      fullWidth
                      name="email"
                      margin="dense"
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      value={filter?.email?.value || ''}
                      onChange={filterTextChange}
                    ></TextField>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortOrderBy === 'username'}
                      direction={sortDirection}
                      onClick={() => createSortHandler('username')}
                    >
                      Username
                    </TableSortLabel>
                    <TextField
                      fullWidth
                      name="username"
                      margin="dense"
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      value={filter?.username?.value || ''}
                      onChange={filterTextChange}
                    ></TextField>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortOrderBy === 'tags'}
                      direction={sortDirection}
                      onClick={() => createSortHandler('tags')}
                    >
                      Tags
                    </TableSortLabel>
                    <TextField
                      fullWidth
                      name="tags"
                      margin="dense"
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      value={filter?.tags?.value || ''}
                      onChange={filterTextChange}
                    ></TextField>
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading && <TableLoadingIndicator cols={6} rows={10} bodyOnly={true} />}
              <TableBody>
                {!loading && users?.map((user, i) =>
                  <TableRow key={i} style={getStripedStyle(i)}>
                    <TableCell>
                      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Chip label={user?.isActive === false ? 'Inactive' : 'Active'}
                              size={"small"}
                              color={user?.isActive === false ? '' : 'primary'}
                        />
                        <Avatar src={user?.picture}
                                style={{marginLeft: '.5rem'}}
                        >
                          <FaceIcon />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Link component={RouterLink} to={`/users/${user?._id}`}>{user.firstName}</Link>
                    </TableCell>
                    <TableCell>
                      {user?.lastName}
                    </TableCell>
                    <TableCell>
                      {user?.email}
                    </TableCell>
                    <TableCell>
                      {user?.username}
                    </TableCell>
                    <TableCell>
                      {user?.tags?.map(t => <Chip style={{marginRight: '.25rem'}} label={t}/>)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    )
  } else {
    return (
      <Paper elevation={24}
             className={classes.root}
      >
        <Grid container
              justify={"center"}
              alignItems={"center"}
        >
          <Avatar className={classes.avatar}>
            <WarningRounded />
          </Avatar>
          <div>
            <Typography variant={"h6"}>Your account does not have the permissions view users.</Typography>
            <Typography variant={"p"}>Please contact your system admin.</Typography>
          </div>
        </Grid>
      </Paper>
    )
  }
}

export const UsersWrapper = () => {
  return (
    <Switch>
      <PrivateRoute exact path={"/users"} component={Users} />
      <PrivateRoute exact path="/users/:id" component={User} />
    </Switch>
  );
};
