import React from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Hidden,
  Divider,
  FormGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
} from '@material-ui/core';
import Transition from '../../dialog/transition';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { UserContext } from '../../../context/usercontext';
import useHasRole from "../../../hooks/user.role.hook";
import VitalModel from '../../resident/medicationorders/emar/modelTemplate';
import {FormFieldsGenerator} from "../../../utils/forms";
import {displayHoldInfo, isMedicationOrderOnHold, isValid} from '../../resident/medicationorders/emar/utils';
import {ObjectId} from "bson";
import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {getTenantUsers} from "../../../services/tenant.service";
import TableLoadingIndicator from "../../../utils/indicators/table.loading";
import {getFrequencies} from "../../../services/frequency.service";
import {Alert} from "@material-ui/lab";
import {yellow} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {

  },
  dialogContent: {
    padding: theme.spacing(8, 1, 0, 1),
  },
  vitalsContainer: {
    margin: theme.spacing(1,0),
    border: '1px solid',
    borderColor: theme.palette.grey[500],
    padding: theme.spacing(2, .5)
  },
  isHold: {
    backgroundColor: yellow[100]
  }
}));

const EmarDialog = ({ open, onClose, onSave, model, date }) => {
  const [event, setEvent] = React.useState({});
  const [vitalModel, setVitalModel] = React.useState(null);
  const { selectedTenant } = React.useContext(UserContext);
  const [loadingUsers, setLoadingUsers] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const classes = useStyles();
  const { roles } = React.useContext(UserContext);
  const { checkPermission } = useHasRole();
  const [frequencies, setFrequencies] = React.useState([]);
  const canUpdate = checkPermission('EMAR_UPDATE');

  React.useEffect(() => {
    const fetchFreqs = async () => {
      const frequencyResponse = await getFrequencies();
      if (!frequencyResponse?.error) {
        setFrequencies(frequencyResponse);
      }
    }
    if (open && model) {
      fetchFreqs();
      setEvent(model);
      if (model?.chartVitals) {
        const obj = JSON.parse(JSON.stringify(VitalModel));
        Object.keys(model?.vital).forEach(key => {
          if (obj.hasOwnProperty(key)) {
            obj[key].value = model.vital[key];
          }
        });
        Object.keys(obj).forEach(key => {
          if (key !== 'date') {
            obj[key].hidden = !(model?.chartVitalsList?.indexOf(obj[key].key) > -1);
          }
        })
        setVitalModel(obj);
      }
    }
  }, [open, model]);


  React.useEffect(() => {
    if (selectedTenant?._id && open && canUpdate) {
      populateUsers();
    }
    // eslint-disable-next-line
  }, [open, canUpdate, selectedTenant]);

  const populateUsers = async () => {
    setLoadingUsers(true);
    if (users?.length < 1) {
      const userResponse = await getTenantUsers(selectedTenant?._id);
      if (!userResponse?.error) {
        setUsers(userResponse?.sort((a, b) => a?.lastName > b?.lastName ? 1: -1));
      } else {
        setUsers([]);
      }
    }
    setLoadingUsers(false);
  }

  const onEventChange = (e) => {
    const obj = { ...event };
    obj[e.target.name] = e.target.value;
    setEvent(obj);
  };

  const onEditStatus = (e) => {
    const obj = { ...event };
    if (obj) {
      if (obj.status) {
        obj.status.name = e.target.value;
      } else {
        obj.status = { name: e.target.value };
      }
    }
    setEvent(obj);
  };

  const canEdit = (event) => {
    if (!canUpdate) return false;
    const current = moment();
    const start = moment(event.startWindow);
    const end = moment(event.endWindow);

    let updateMissedMedicationPermissions = false;
    if (roles) {
      updateMissedMedicationPermissions =
        roles.filter(
          (userRole) =>
            userRole.role.permissions.indexOf('UPDATE_MISSED_MEDICATION') > -1
        ).length > 0;
    }
    if (updateMissedMedicationPermissions && current >= start) {
      return true;
    } else if (current >= start && current < end) {
      return true;
    } else {
      return false;
    }
  };

  const canEditQty = (order) => {
    const current = moment();
    const start = moment(event.startWindow);
    const end = moment(event.endWindow);
    if (current >= start && current < end) {
      return order.isQtyEditable;
    } else {
      return false;
    }
  };

  const onVitalChange = (e) => {
    const obj = {...vitalModel, ...e};
    setVitalModel(obj);
    const payload = {};
    Object.keys(obj).forEach(key => {
      payload[key] = obj[key].value;
    });
    const objEvent = {...event};
    objEvent.updatedVital = payload;
    setEvent(objEvent);
  }

  const saveEmar = () => {
    let vital = {};
    if (model?.chartVitals && vitalModel) {
      Object.keys(vitalModel).forEach(key => {
        vital[key] = vitalModel[key].value;
      });
      if (ObjectId.isValid(model?.resident)) {
        vital.resident = model?.resident;
      } else {
        vital.resident = model?.resident?._id;
      }
    }
    event.updatedVital = vital;
    onSave(event)
  }

  const getAdministeredUserLabel = administeredUser => {
    let user = '';
    if (administeredUser) {
      user = `${administeredUser?.firstName ?? ''} ${administeredUser?.lastName ?? ''} `
      if (administeredUser?.initials) {
        user += ` (${administeredUser?.initials})`
      }
    }
    return user;
  }

  const onAdministeredTimestampChanged = date => {
    const obj = {...event};
    obj.administeredTimestamp = date;
    setEvent(obj);
  }

  const onAdministeredUserChange = e => {
    const user = users?.find(user => user?._id === e.target.value);
    const obj = {...event};
    obj.administeredUser = user;
    setEvent(obj);
  }

  const getFrequencyDetails = () => {
    const di = event?.medicationOrder?.dosageInstructions?.find(di => di._id === event?.dosageInstruction ||
      di._id === event?.dosageInstruction?._id ||
      di._id === event?.dosageInstruction
    );
    if (di) {
      const found = frequencies?.find(f => f._id === di.frequency || f._id === di.frequency?._id);
      if (found) {
        return `${found.name} - ${found.description}`;
      }
    }
    return null;
  }

  return (
    <Dialog TransitionComponent={Transition} fullScreen open={open} fullWidth className={classes.root}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Resident eMar
          </Typography>
        </Toolbar>
      </AppBar>


      <DialogContent className={classes.dialogContent}>
        {isMedicationOrderOnHold(event?.medicationOrder, date) &&
          <Alert severity={"warning"} className={classes.isHold}>
            {displayHoldInfo(event?.medicationOrder, date)}
          </Alert>
        }
        <Grid container justify="space-between">
          <Grid item xs={12} sm={6}>
            {event ? (
              <div>
                <Typography variant="h6">
                  {event.resident ? (
                    <span>
                      {event.resident.firstName} {event.resident.lastName}
                    </span>
                  ) : null}
                </Typography>
                <Typography variant="subtitle1">
                  {event.medication ? event.medication.tradeName : null}
                </Typography>
                <Typography variant="subtitle1">
                  Strength:{' '}
                  {event.medication ? event.medication.strength : null}{' '}
                  {event.medication && event.medication.unit
                    ? event.medication.unit.name
                    : null}
                </Typography>
                <Typography variant="subtitle1">
                  {event.scheduledTime &&
                    moment(event.scheduledTime).format('h:mm a')}
                </Typography>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Hidden smUp>
              <Divider />
            </Hidden>
            <Typography variant="subtitle2">
              Frequency: {getFrequencyDetails(event?.dosageInstruction)}
            </Typography>
            <Typography variant="subtitle2">
              Pill Description: {event.pillDescription}
            </Typography>
            <Typography variant="subtitle2">
              Indication: {event.indication}
            </Typography>
            <Typography variant="subtitle2">
              Direction: {event.direction}
            </Typography>
            <Typography variant="subtitle2">Alerts: {event.alerts}</Typography>
            <Hidden smUp>
              <Divider />
            </Hidden>
          </Grid>
        </Grid>
        {event && (
          <React.Fragment>
            <form>
              <FormGroup>
                <TextField
                  disabled={!canEditQty(event)}
                  value={event.passingQty}
                  name="passingQty"
                  onChange={onEventChange}
                  label="Qty"
                />
                <FormControl>
                  <InputLabel htmlFor="status">Status</InputLabel>
                  <Select
                    id="status"
                    label="Status"
                    disabled={!canEdit(event)}
                    value={event.status ? event.status.name : null}
                    onChange={onEditStatus}
                    name="medicationOrderEvsentStatusId"
                  >
                    <MenuItem
                      disabled="true"
                      style={{ display: 'none' }}
                      value={'Scheduled'}
                    >
                      Scheduled
                    </MenuItem>
                    <MenuItem value={'Given'}>Given</MenuItem>
                    <MenuItem
                      disabled="true"
                      style={{ display: 'none' }}
                      value={'Late'}
                    >
                      Late
                    </MenuItem>
                    <MenuItem value={'Missed'}>Missed</MenuItem>
                    <MenuItem value={'Hold'}>Hold</MenuItem>
                    <MenuItem value={'SA'}>Self Administered</MenuItem>
                    <MenuItem value={'NA'}>Not Administered</MenuItem>
                    <MenuItem value={'Refused'}>Refused</MenuItem>
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    label={"Administered Date"}
                    disabled={!canEdit(event)}
                    fullWidth
                    variant={"inline"}
                    inputSize={"small"}
                    name={"administeredTime"}
                    value={event?.administeredTimestamp}
                    allowKeyboardControl="true"
                    format={"MM/DD/YYYY hh:mm a"}
                    onChange={onAdministeredTimestampChanged}
                  />
                </MuiPickersUtilsProvider>
                {canEdit(event) &&
                  <>
                    {loadingUsers && <TableLoadingIndicator rows={0} cols={1} />}
                    {!loadingUsers &&
                    <FormControl>
                      <InputLabel shrink>Administered By</InputLabel>
                      <Select value={event?.administeredUser?._id}
                              onChange={onAdministeredUserChange}
                      >
                        <MenuItem>Select User</MenuItem>
                        {users?.map(user =>
                          <MenuItem value={user?._id}>
                            {getAdministeredUserLabel(user)}
                          </MenuItem>)
                        }
                      </Select>
                    </FormControl>

                    }
                  </>
                }
                {!canEdit(event) &&
                  <TextField disabled
                             label={"Administered User"}
                             value={getAdministeredUserLabel(event?.administeredUser)}
                  />
                }
                <TextField
                  label="Note"
                  disabled={!canEdit(event)}
                  id="outlined-multiline-static"
                  multiline
                  name="note"
                  onChange={onEventChange}
                  value={event.note || ''}
                />
              </FormGroup>
            </form>
            {model?.chartVitals &&
              <div className={classes.vitalsContainer}>
                <Typography variant={"h6"}>Vitals</Typography>
                <FormFieldsGenerator model={vitalModel} onChange={onVitalChange} />
              </div>
            }
          </React.Fragment>
        )}
        {canEdit(event) && (
          <DialogActions>
            <ButtonGroup fullWidth>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={saveEmar}
                disabled={!isValid(event)}
              >
                Update
              </Button>
            </ButtonGroup>
          </DialogActions>
        )}
        {!canEdit(event) && (
          <DialogActions>
            <ButtonGroup fullWidth>
              <Button variant="contained" color="primary" onClick={onClose}>
                Close
              </Button>
            </ButtonGroup>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EmarDialog;
